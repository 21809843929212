<template>
  <div id="Forget-Password">
    <div>
      <h3 :style="{ color: '#fff' }">请按照下图所示，提供《修改密码声明书》</h3>
      <h4 :style="{ color: '#fff' }">将照片发送至客服人员进行办理</h4>
      <Image-Zoom
        :img="`${$public}/images/logo/Forget-Password.png`"
        :zoom="true"
      />
      <div :style="{ marginTop: '1em', paddingLeft: '1em' }">
        <p>注意：</p>
        <p>1：需要修改密码的帐号必须本人亲自办理</p>
        <p>2：照片需要看清声明书上的文字、身份证上的头像及文字</p>
        <!-- <p>3: 需要</p> -->
      </div>
    </div>
    <div :style="{ marginTop: '1em' }">
      <a-button @click="$router.push({ name: 'Login' })">返回</a-button>
    </div>
  </div>
</template>

<script>
import ImageZoom from "@/components/Image-Zoom";

export default {
  components: {
    ImageZoom,
  },
};
</script>

<style lang="less">
#Forget-Password {
}
</style>
