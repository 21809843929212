var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"image-zoom"}},[_c('div',{staticClass:"image"},[_c('div',{staticClass:"image-transform"},[_c('img',{ref:"image-zoom",attrs:{"src":`${
          _vm.thumbnail.indexOf('://') !== -1 || _vm.thumbnail.indexOf('base') !== -1
            ? _vm.thumbnail
            : _vm.img.indexOf('://') !== -1 || _vm.img.indexOf('base') !== -1
            ? _vm.img
            : _vm.baseURL + (_vm.thumbnail ? _vm.thumbnail : _vm.img ? _vm.img : '')
        }`,"alt":_vm.alt},on:{"click":_vm.image_zoom}})])]),(_vm.theFirstTime)?_c('div',{staticClass:"image-full",attrs:{"id":"image-full"},on:{"click":_vm.image_cancel}},[_c('div',{staticClass:"image-full-body"},[_c('div',{staticClass:"image"},[_c('img',{ref:"image-zoom",attrs:{"src":`${
            _vm.img.indexOf('://') !== -1 || _vm.img.indexOf('base') !== -1
              ? _vm.img
              : _vm.baseURL + (_vm.img ? _vm.img : '')
          }`,"alt":_vm.alt}})]),_c('div',{ref:"image-touch",staticClass:"image-touch",on:{"touchstart":(e) => _vm.image_touch(e, 'start'),"touchmove":(e) => _vm.image_touch(e, 'move'),"touchend":(e) => _vm.image_touch(e, 'end')}},[_c('a-button-group',{staticClass:"btn"},[_c('a-button',{on:{"click":(e) => _vm.image_handle_zoom(e, 'magnify')}},[_c('a-icon',{attrs:{"type":"zoom-in"}})],1),(
              _vm.imageInfo.style.width !== _vm.imageInfo.style.naturalWidth &&
              _vm.imageInfo.style.parentWidth <= _vm.imageInfo.style.width
            )?_c('a-button',{on:{"click":(e) => _vm.image_handle_zoom(e, 'all')}},[_vm._v(" 1:1 ")]):_c('a-button',{on:{"click":(e) => _vm.image_handle_zoom(e, 'full')}},[(_vm.imageInfo.style.parentWidth <= _vm.imageInfo.style.width)?_c('a-icon',{attrs:{"type":"fullscreen-exit"}}):_c('a-icon',{attrs:{"type":"fullscreen"}})],1),_c('a-button',{on:{"click":(e) => _vm.image_handle_zoom(e, 'shrink')}},[_c('a-icon',{attrs:{"type":"zoom-out"}})],1)],1)],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }