<template>
  <div id="image-zoom">
    <div class="image">
      <div class="image-transform">
        <img
          :src="`${
            thumbnail.indexOf('://') !== -1 || thumbnail.indexOf('base') !== -1
              ? thumbnail
              : img.indexOf('://') !== -1 || img.indexOf('base') !== -1
              ? img
              : baseURL + (thumbnail ? thumbnail : img ? img : '')
          }`"
          ref="image-zoom"
          :alt="alt"
          @click="image_zoom"
        />
      </div>
    </div>
    <div
      id="image-full"
      class="image-full"
      v-if="theFirstTime"
      @click="image_cancel"
    >
      <div class="image-full-body">
        <div class="image">
          <img
            ref="image-zoom"
            :src="`${
              img.indexOf('://') !== -1 || img.indexOf('base') !== -1
                ? img
                : baseURL + (img ? img : '')
            }`"
            :alt="alt"
          />
        </div>
        <div
          class="image-touch"
          ref="image-touch"
          @touchstart="(e) => image_touch(e, 'start')"
          @touchmove="(e) => image_touch(e, 'move')"
          @touchend="(e) => image_touch(e, 'end')"
        >
          <a-button-group class="btn">
            <a-button @click="(e) => image_handle_zoom(e, 'magnify')">
              <a-icon type="zoom-in" />
            </a-button>
            <a-button
              v-if="
                imageInfo.style.width !== imageInfo.style.naturalWidth &&
                imageInfo.style.parentWidth <= imageInfo.style.width
              "
              @click="(e) => image_handle_zoom(e, 'all')"
            >
              1:1
            </a-button>
            <a-button v-else @click="(e) => image_handle_zoom(e, 'full')">
              <a-icon
                v-if="imageInfo.style.parentWidth <= imageInfo.style.width"
                type="fullscreen-exit"
              />
              <a-icon v-else type="fullscreen" />
            </a-button>
            <a-button @click="(e) => image_handle_zoom(e, 'shrink')">
              <a-icon type="zoom-out" />
            </a-button>
            <!-- <a-button @click="(e) => image_handle_zoom(e, 'redo')">
              <a-icon type="redo" />
            </a-button> -->
          </a-button-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alt: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    thumbnail: {
      type: String,
      default: "",
    },
    zoom: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    const baseURL = "" + "";
    return {
      theFirstTime: false,
      imageInfo: {
        touches: [],
        distance: 0,
        style: {},
      },
      baseURL,
    };
  },
  methods: {
    image_zoom(e) {
      e.stopPropagation(); // 阻止事件冒泡
      if (!this.zoom) return;

      this.theFirstTime = true;
      const a = setInterval(() => {
        if (document.getElementById("image-full")) {
          clearInterval(a);
          const dom = document
            .getElementById("image-full")
            .getElementsByTagName("img")[0];

          Object.assign(dom.style, {
            position: "absolute",
            "max-width": "unset",
            "max-height": "unset",
            width: dom.parentNode.parentNode.offsetWidth + "px",
            height: "auto",
          });

          let setIntervalTime = 1000;
          let setIntervalID = setInterval(() => {
            if (
              setIntervalTime-- === 0 ||
              dom.width === dom.parentNode.parentNode.offsetWidth
            ) {
              clearInterval(setIntervalID);
              Object.assign(dom.style, {
                top: -(dom.height / 2) + "px",
                left: -(dom.width / 2) + "px",
              });

              this.imageInfo.style = {
                // 图片尺寸
                width: dom.width,
                height: dom.height,
                // 图片位置
                top: -(dom.height / 2),
                left: -(dom.width / 2),
                naturalWidth: dom.naturalWidth,
                naturalHeight: dom.naturalHeight,
                parentWidth: dom.parentNode.parentNode.offsetWidth,
                parentHeight: dom.parentNode.parentNode.offsetHeight,
              };
            }
          });
        }
      }, 50);
    },
    image_cancel(e) {
      e.stopPropagation(); // 阻止事件冒泡
      e.preventDefault(); // 阻止事件下沉
      this.theFirstTime = false;
    },
    image_touch(e, handle) {
      e.stopPropagation(); // 阻止事件冒泡
      if (1 < e.touches.length) e.preventDefault(); // 阻止事件下沉

      const dom = e.target.parentNode.getElementsByTagName("img")[0];

      switch (handle) {
        case "start":
          for (let i in [...e.touches])
            this.imageInfo.touches[i] = e.touches[i];
          if (e.touches.length === 1) this.imageInfo.timeStamp = e.timeStamp;
          break;
        case "move":
          if (this.imageInfo.touches.length === 1) {
            this.imageInfo.style.top +=
              e.touches[0].screenY - this.imageInfo.touches[0].screenY;
            this.imageInfo.style.left +=
              e.touches[0].screenX - this.imageInfo.touches[0].screenX;
            this.imageInfo.touches[0] = e.touches[0];
            Object.assign(dom.style, {
              top: this.imageInfo.style.top + "px",
              left: this.imageInfo.style.left + "px",
            });
          }
          break;
        case "end":
          if (!e.touches.length) this.imageInfo.touches = [];
          else if (e.touches.length === 1) {
            this.imageInfo.touches = [];
            this.imageInfo.touches[0] = e.touches[0];
          }
          break;
      }
    },
    image_handle_zoom(e, handle) {
      e.stopPropagation(); // 阻止事件冒泡
      e.preventDefault(); // 阻止事件下沉

      const dom =
        e.target.parentNode.parentNode.parentNode.getElementsByTagName(
          "img"
        )[0];

      if (!this.imageInfo.zoom) this.imageInfo.zoom = 20;
      if (!this.imageInfo.onClickTimeStamp)
        this.imageInfo.onClickTimeStamp = e.timeStamp;
      if (!this.imageInfo.onClickTimeStampCount)
        this.imageInfo.onClickTimeStampCount = 0;

      if (handle === "magnify" && handle === "shrink") {
        const timeStamp = e.timeStamp - this.imageInfo.onClickTimeStamp;
        this.imageInfo.onClickTimeStamp = e.timeStamp;

        if (timeStamp < 300) this.imageInfo.onClickTimeStampCount += 1;
        else {
          this.imageInfo.onClickTimeStampCount = 0;
          this.imageInfo.zoom = 32;
        }

        if (4 < this.imageInfo.onClickTimeStampCount) {
          this.imageInfo.zoom *= 2;
          this.imageInfo.onClickTimeStampCount = 0;
        }
      }

      const all = () => {
        this.imageInfo.style.top = -(dom.naturalHeight / 2);
        this.imageInfo.style.left = -(dom.naturalWidth / 2);
        this.imageInfo.style.width = dom.naturalWidth;
        this.imageInfo.style.height = dom.naturalHeight;
      };
      const full = () => {
        const domParent = dom.parentNode.parentNode;
        let a = 1000;
        let b = setInterval(() => {
          if (a-- === 0 || dom.width === domParent.offsetWidth) {
            clearInterval(b);

            this.imageInfo.style.top = -(dom.height / 2);
            this.imageInfo.style.left = -(dom.width / 2);

            setImageStyle();
          }
        });

        this.imageInfo.style.width = domParent.offsetWidth;
        this.imageInfo.style.height = domParent.offsetHeight;
      };
      const setImageStyle = () => {
        Object.assign(dom.style, {
          top: this.imageInfo.style.top + "px",
          left: this.imageInfo.style.left + "px",
          width: this.imageInfo.style.width + "px",
          style: this.imageInfo.style.height + "px",
        });
      };

      let a = this.imageInfo.zoom;

      switch (handle) {
        case "magnify":
          if (this.imageInfo.style.width < dom.naturalWidth) {
            this.imageInfo.style.top -= a / 2;
            this.imageInfo.style.left -= a / 2;
            this.imageInfo.style.width += a;
            this.imageInfo.style.height += a;
          } else all();
          break;
        case "shrink":
          if (0 < this.imageInfo.style.width) {
            this.imageInfo.style.top += a / 2;
            this.imageInfo.style.left += a / 2;
            this.imageInfo.style.width -= a;
            this.imageInfo.style.height -= a;
          }
          break;
        case "all":
          all();
          break;
        case "full":
          full();
          break;
        case "redo":
          break;
      }

      setImageStyle();
    },
    setImageSize() {
      const image = this.$refs["image-zoom"];
      const [w, h] = [image.naturalWidth, image.naturalHeight];
      image.parentNode.style.paddingBottom = `${(h / w) * 100}%`;
    },
  },
  mounted() {
    this.setImageSize();
  },
};
</script>

<style lang="less" scoped>
#image-zoom {
  > .image-full {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;

    > .image-full-body {
      width: 100% !important;
      height: 100% !important;
      max-width: 95% !important;
      max-height: 95% !important;
      display: flex;
      align-items: center;
      position: relative;
      margin: 0 auto;

      > .image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%); /* Opera */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -webkit-transform: translate(-50%, -50%); /* Safari 和 Chrome */
      }

      > .image-touch {
        position: fixed;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(111, 111, 111, 0.5);
        display: flex;
        justify-content: center;
        align-items: flex-end;

        > .btn {
          margin: 10vh auto;

          .ant-btn {
            @size: 1.6em;
            font-size: 30px !important;
            margin: 0 0.3em;
            padding: 0;
            width: @size !important;
            height: @size !important;
            min-height: unset !important;
            line-height: @size !important;
            border-width: 0;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.6) !important;

            * {
              font-size: 0.8em !important;
            }
          }
        }
      }
    }
  }
}
</style>